import React, { Component } from 'react';
import { Editor, EditorState, RichUtils, ContentState, getDefaultKeyBinding, KeyBindingUtil, convertToRaw, convertFromRaw, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import TimecodeTag from './TimecodeTag'

const { hasCommandModifier } = KeyBindingUtil;
function myKeyBindingFn(e) {
    if (e.keyCode === 66 /* `S` key */ && hasCommandModifier(e)) {
        return 'myeditor-save';
    }
    return getDefaultKeyBinding(e);
}

class TranslatedText extends Component {
    constructor(props) {
        super(props);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
    }
    componentDidMount() {
        if (this.props.text['translated']) {
            this.setState({ editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.text['translated']))) })
        } else {
            this.translateText(this.props.text['Transcript']);
        }
    }

    translateText(text) {
        const params = new URLSearchParams({
            auth_key: window.localStorage.getItem('deepLKey'),
            target_lang: 'FR',
            text: text
        })
        const options = {
            method: 'GET'
        }
        const requestUrl = 'https://api-free.deepl.com/v2/translate?' + params;
        fetch(requestUrl, options)
            .then((response) => response.json())
            .then((json) => {
                let editorState = EditorState.createWithContent(ContentState.createFromText(json.translations[0].text + ''))
                this.onChange(editorState);
            })
            .catch(err => console.log(err));
    }

    // Intercept Ctrl + B and do selectText()
    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (command === 'myeditor-save') {
            this.selectText(editorState);
            return 'handled';
        }
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }
    // For the button 
    _onBoldClick() {
        this.selectText();
    }

    // Gets the start and end of the selection, converts it to percentage and send it to TextItem
    selectText(editorState) {
        if (!editorState) editorState = this.state.editorState;
        let selectionFocus = editorState.getSelection().getEndOffset();
        let selectionAnchor = editorState.getSelection().getStartOffset();

        console.log(selectionFocus, selectionAnchor)
        const textLength = editorState.getCurrentContent().getPlainText().length;


        if (selectionFocus === selectionAnchor) {
            console.log('ui')
            selectionFocus = textLength;
            selectionAnchor = 0;

            let selectionState = SelectionState.createEmpty(editorState.getCurrentContent().getFirstBlock().key);
            selectionState = selectionState.merge({
                focusOffset: textLength,
                anchorOffset: 0,
            })

            editorState = EditorState.acceptSelection(editorState, selectionState)
            this.onChange(editorState)
        }


        let selection = {
            focus: selectionFocus / textLength,
            anchor: selectionAnchor / textLength,
            selection: editorState.getSelection()
        }
        console.log(selection);
        this.props.selectText(selection);
        this.onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    }

    // Updates the editor and send to TextItem for saving
    onChange(editorState) {
        this.setState({ editorState }, () => {
            let text = window.localStorage.getItem('text');
            text = JSON.parse(text);
            text[this.props.itemId]['translated'] = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
            window.localStorage.setItem('text', JSON.stringify(text));
        });
    }
    buttonSelect = this._onBoldClick.bind(this);
    render() {

        if (!this.state || !this.state.editorState) return (
            <div className="loader-wrapper">
                <div className="loader"></div>
            </div>
        )

        return (<div className='block'>
            <TimecodeTag start={this.props.text["Start Time"]} end={this.props.text["End Time"]} buttonSelect={this.buttonSelect} />
            <Editor
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange.bind(this)}
                stripPastedStyles={true}
                keyBindingFn={myKeyBindingFn}
            />
        </div>)
    }
}

export default TranslatedText;