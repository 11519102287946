import React, { Component } from 'react';
import { Editor, EditorState, RichUtils, ContentState, SelectionState, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import TimecodeTag from './TimecodeTag'


class OriginalText extends Component {
    constructor(props) {
        super(props);
        this.state = { // Creates text from saved text if it exists, from original untouched text if not
            editorState: EditorState.createWithContent(
                this.props.text['original'] ?
                    convertFromRaw(JSON.parse(this.props.text['original']))
                    : ContentState.createFromText(this.props.text['Transcript'] + '')
            )
        };
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
    }
    componentDidMount() {
        this.onChange(this.state.editorState); // Update to save to localstorage 
        // this.props.changed(convertToRaw(this.state.editorState.getCurrentContent()));
    }

    // When the props change (ie TranslatedText got edited, we select and change the weight of the text according to selection percentage)
    componentDidUpdate(oldProps) {
        if (oldProps !== this.props) {
            const editorState = this.state.editorState;
            var selectionState = SelectionState.createEmpty(editorState.getCurrentContent().getFirstBlock().getKey());

            const selectionFocus = this.props.selection.focus;
            const selectionAnchor = this.props.selection.anchor;
            const textLength = editorState.getCurrentContent().getPlainText().length;


            var updatedSelection = selectionState.merge({
                focusOffset: textLength * selectionFocus,
                anchorOffset: textLength * selectionAnchor,
            });


            let updatedEditorState = EditorState.acceptSelection(editorState, updatedSelection);
            updatedEditorState = RichUtils.toggleInlineStyle(updatedEditorState, 'BOLD');

            this.onChange(updatedEditorState);
        }
    }

    handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            this.onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }
    // For the button
    _onBoldClick() {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
    }

    // Updates the editor and send to TextItem for saving
    onChange(editorState) {
        this.setState({ editorState }, () => {
            let text = window.localStorage.getItem('text');
            text = JSON.parse(text);
            text[this.props.itemId]['original'] = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
            window.localStorage.setItem('text', JSON.stringify(text));
        });
    }

    buttonSelect = this._onBoldClick.bind(this);

    render() {
        return (<div className='block'>
            <TimecodeTag start={this.props.text["Start Time"]} end={this.props.text["End Time"]} buttonSelect={this.buttonSelect} />
            <Editor
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange.bind(this)}
                stripPastedStyles={true}
            />
        </div>)
    }
}

export default OriginalText;