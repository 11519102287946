import React, { Component } from 'react';

import TranslatedText from './TranslatedText'
import OriginalText from './OriginalText'

class TextItem extends Component {
    constructor(props) {
        super(props);
        this.state = { selection: '', translatedText: '', originalText: '' }
    }
    selectText(selection) {
        this.setState({ selection: selection }, () => { console.log(this.state.selection) })
    }


    render() {
        if (!this.props.originalText) return (<>Empty</>);

        const text = this.props.originalText;



        return (<div className='columns mx-6'>
            <div className='column'>
                <OriginalText text={text} selection={this.state.selection} itemId={this.props.itemId} />
            </div>
            {this.props.translate ?
                <div className='column'>
                    <TranslatedText text={text} selectText={this.selectText.bind(this)} itemId={this.props.itemId} />
                </div>
                : null}
        </div>)
    }
}

export default TextItem;