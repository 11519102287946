/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import './App.css';
import TextItem from './TextItem'
import Review from './Review'
import DragAndDrop from './DragAndDrop'
import Papa from 'papaparse';
// import draftToMarkdown from 'draftjs-to-markdown';


class App extends Component {

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }
  state = {
    showSidebar: false,
    isSelectingFile: false,
    hasText: false,
    file: undefined,
    text: undefined,
    deepLKey: undefined,
    view: 'edit',
    translate: false
  }

  componentDidMount() {
    if (window.localStorage.getItem('deepLKey') !== undefined) {
      this.setState({ deepLKey: window.localStorage.getItem('deepLKey') }, this.requestDeepLUsage)
    }
    this.getStoredData();
  }

  getStoredData() {
    if (window.localStorage.getItem('text') !== undefined) {
      this.setState({
        'text': JSON.parse(window.localStorage.getItem('text')),
        hasText: true,
        filename: window.localStorage.getItem('filename')
      });
    }
    if (window.localStorage.getItem('translate') && window.localStorage.getItem('translate') === 'true') {
      this.setState({
        'translate': true,
      });
    }

  }



  handleDrop = (files) => {
    this.setState({ isSelectingFile: false, error: undefined })
    if (files.length > 1) {
      this.setState({ error: 'Too many files' });
      return;
    } else if (files.length < 1) {
      this.setState({ error: 'No file' });
      return;
    }

    const file = files[0];
    const fileName = file.name;

    const split = fileName.split(".");

    this.setState({ filename: split[0] });
    window.localStorage.setItem('filename', split[0])

    const extension = split[split.length - 1];

    if (extension !== 'csv' && extension !== 'json') {
      this.setState({ error: 'Not a valid file' });
      return;
    } else {
      if (extension === 'csv') {
        this.parseText(file);
      } else {
        this.openProject(file);
      }
    }
  }

  openProject(file) {
    let reader = new FileReader()
    reader.readAsText(file)
    reader.onload = function (e) {
      let save = JSON.parse(e.target.result);
      if (!save.key || save.key !== 'mvtbl') {
        this.setState({ error: 'Not a valid file' });
      } else {
        window.localStorage.setItem('text', save.text);
        window.localStorage.setItem('filename', save.filename);
        window.localStorage.setItem('translate', save.translate);
        this.getStoredData();
      }
    }.bind(this)

  }

  selectFile() {
    this.fileInputRef.current.click();
  }

  parseText(file) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        window.localStorage.setItem('text', JSON.stringify(results.data));
        this.setState({ hasText: true, text: results.data, file: file })
      },
    });
  }

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  reset() {
    window.localStorage.removeItem('text');
    window.localStorage.removeItem('filename');
    window.localStorage.removeItem('translate');
    this.setState({ text: undefined, file: undefined, hasText: false, translate: false })
  }
  resetAPI() {
    window.localStorage.removeItem('deepLKey');
    window.localStorage.removeItem('translate');
    this.setState({ deepLKey: undefined, translate: false })
  }

  registerDeepLKey() {
    if (document.getElementById('deepLKey').value) {
      let deepLKey = document.getElementById('deepLKey').value;
      const params = new URLSearchParams({
        auth_key: deepLKey
      })
      const options = {
        method: 'GET'
      }
      const requestUrl = 'https://api-free.deepl.com/v2/usage?' + params;
      fetch(requestUrl, options)
        .then((response) => response.json())
        .then((json) => {
          window.localStorage.setItem('deepLKey', deepLKey)
          window.localStorage.setItem('translate', true)
          this.setState({ deepLKey: deepLKey, askForKey: false, translate: true }, this.requestDeepLUsage)
        })
        .catch((err) => {
          console.error('Key is not valid')
        });
    }
  }

  requestDeepLUsage() {
    let deepLKey = this.state.deepLKey;
    const params = new URLSearchParams({
      auth_key: deepLKey
    })
    const options = {
      method: 'GET'
    }
    const requestUrl = 'https://api-free.deepl.com/v2/usage?' + params;
    fetch(requestUrl, options)
      .then((response) => response.json())
      .then((json) => {
        this.setState({
          usage: json.character_count,
          maxUsage: json.character_limit,
          percentUsage: json.character_count / json.character_limit,
        })
      })
      .catch((err) => {
        console.error('Key is not valid')
      });
  }
  viewEdit() {
    this.getStoredData();
    this.setState({ view: 'edit' });
  }
  viewReview() {
    this.setState({ view: 'review' });
  }

  translate() {
    if (this.state.translate) { // Si on affiché déjà une traduction, on n'affiche plus
      window.localStorage.setItem('translate', false)
      this.setState({ translate: false });
    } else { //Sinon on affiche

      if (this.state.text[0].translated || this.state.deepLKey) { // Si on a une traduc enregistrée ou une clé DeepL, on affiche/traduit 
        window.localStorage.setItem('translate', true)
        this.setState({ translate: true });
      } else { // Sinon, on demande une clé
        this.setState({ 'askForKey': true })
      }

    }
  }

  render() {
    const selectFileBound = this.selectFile.bind(this);
    const handleDropBound = this.handleDrop.bind(this);

    let usageProgress = 'progress'
    if (this.state.percentUsage >= .8) usageProgress += ' is-danger';
    else if (this.state.percentUsage >= .6) usageProgress += ' is-warning';
    else usageProgress += ' is-info';

    return (

      <div>
        <div>
          <section className="hero is-primary">
            <div className="hero-body">
              <p className="title">
                Montage via transcriptions
              </p>

              <div className="subtitle">
                <span className='level buttons are-small'>
                  <div className='level-left'>

                    {this.state.text ?
                      <>
                        {!this.state.translate ?
                          <button className='button is-info is-light' onClick={this.translate.bind(this)}>Afficher la traduction</button>
                          : <button className='button is-info is-light' onClick={this.translate.bind(this)}>Cacher la traduction</button>}
                        {/* <button className='button is-success is-light' onClick={this.save.bind(this)}>Télécharger</button> */}
                        <button className='button is-danger is-light' onClick={this.reset.bind(this)}>Réinitialiser</button>
                      </>

                      : null}
                  </div>

                  {this.state.deepLKey ?
                    <div className='level-right'>
                      <div className='content has-text-centered'>
                        <progress className={usageProgress} title={Math.round(this.state.percentUsage * 100) + '% (' + this.state.usage + '/' + this.state.maxUsage + ')'} value={this.state.usage} max={this.state.maxUsage}>{this.state.percentUsage}</progress>
                        <button className='button is-danger is-light' onClick={this.resetAPI.bind(this)}>Déconnexion</button>
                      </div>
                    </div>

                    : null}

                </span>
              </div>
            </div>
          </section>


          <DragAndDrop handleDrop={this.handleDrop} isSelectingFile={this.state.isSelectingFile} className='section'>
            {this.state.text ?
              <div>
                <div className="tabs is-centered">
                  <ul>
                    <li className={this.state.view === 'edit' ? 'is-active' : null}><a onClick={this.viewEdit.bind(this)}>Édition</a></li>
                    <li className={this.state.view === 'review' ? 'is-active' : null}><a onClick={this.viewReview.bind(this)}>Review & Script</a></li>
                  </ul>
                </div>
                <div className='my-3'>
                  {this.state.view === 'edit' ?

                    this.state.text.map((text, index) => (
                      <div key={index}>
                        {text.Transcript ?
                          <div key={index} >
                            <TextItem originalText={text} itemId={index} text={this.state.text} translate={this.state.translate} />
                          </div>
                          : null
                        }
                      </div>
                    ))

                    :
                    <Review />
                  }
                </div>
              </div>
              : <div className='section is-large' onClick={selectFileBound}>
                <div className='container has-text-centered has-text-weight-semibold is-size-3 has-text-grey is-italic is-unselectable'>
                  Veuillez déposer une transcription (CSV) ou un projet (JSON)
                </div>
                <input type="file" id="inputFile" ref={this.fileInputRef} style={{ display: "none" }} onChange={(e) => handleDropBound(e.target.files)} />

                {this.state.error ?
                  <div className="notification is-danger is-light">
                    <button className="delete"></button>
                    Fichier invalide.
                  </div>
                  : null}

              </div>





            }
          </DragAndDrop>

          <div className={(this.state.askForKey ? 'is-active' : null) + ' modal'}>
            <div className="modal-background" onClick={this.props.close}></div>
            <div className='modal-card is-medium'>
              <div className='container has-text-centered has-text-weight-semibold is-size-4 has-text-grey'>

                <div className='content mb-3'>Veuillez entrer votre clé d'authentification pour l'API de DeepL</div>
                <div className="field has-addons">
                  <div className="control is-expanded">
                    <input className="input is-large" type="text" id='deepLKey' placeholder="Clé d'authentification pour l'API de DeepL" />
                  </div>
                  <div className="control">
                    <button className="button is-info is-large" onClick={this.registerDeepLKey.bind(this)}>
                      Valider
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="content has-text-centered">
              <p>
                <strong>Montage via transcriptions</strong> by <a href="https://brunolefevre.net">Bruno Lefèvre</a>.
              </p>
            </div>
          </footer>

        </div >
      </div >
    );
  }
}

export default App;
