import React, { Component } from 'react';

class AHKExport extends Component {
    constructor(props) {
        super(props);
        this.state = { delay: 250 }
    }


    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }


    createScript() {
        const delay = this.state.delay;
        // Setup
        let script = '#NoEnv\n';
        script += 'SendMode Input\n';
        script += '#SingleInstance force\n';

        // Switch to Premiere
        script += 'WinActivate, Adobe Premiere Pro\n';

        script += 'Sleep, ' + delay + '\n';
        script += 'Send, ^+x\n'; // Clear in/out
        script += 'Sleep, ' + delay + '\n';

        // For every 
        const originalText = this.props.originalText;
        for (let i = originalText.length - 1; i >= 0; i--) {

            let endTimecode = originalText[i].end.replace(/(\d)/gm, '{Numpad$1}').replace(':', '')
            let startTimecode = originalText[i].start.replace(/(\d)/gm, '{Numpad$1}').replace(':', '')
            console.log(endTimecode)


            script += 'Send, ^+a\n'; // Unselect everything
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, ' + endTimecode + '\n';
            script += 'Sleep, ' + delay + '\n'; // Go to end timecode
            script += 'Send, {NumpadEnter}\n';
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, i\n'; // Set in point to end of text
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, ^+a\n'; // Unselect everything
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, +{Del}\n'; // Ripple delete
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, ' + startTimecode + '\n';
            script += 'Sleep, ' + delay + '\n'; // Go to start timecode
            script += 'Send, {NumpadEnter}\n';
            script += 'Sleep, ' + delay + '\n';
            script += 'Send, o\n'; // Set out point to start of text
            script += 'Sleep, ' + delay + '\n';
        }
        script += 'Send, ^+a\n'; // Unselect everything
        script += 'Sleep, ' + delay + '\n';
        script += 'Send, +{Del}\n'; // Ripple delete
        script += 'Sleep, ' + delay + '\n';
        script += 'SoundPlay *-1\n'; // Play sound! :D
        script += 'Return'; // Ripple delete

        console.log(script);
        this.download(window.localStorage.getItem('filename') + '.ahk', script);
    }

    changeDelay(event) {
        this.setState({ delay: event.target.value });
    }


    render() {
        return (<div className={this.props.enable ? 'modal is-active' : 'modal'}>
            <div className="modal-background" onClick={this.props.close}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Script AutoHotKey</p>
                    <button className="delete" aria-label="close" onClick={this.props.close}></button>
                </header>
                <section className="modal-card-body">
                    <div className='content'>
                        Script à utiliser avec <a href="https://www.autohotkey.com/">AutoHotKey</a>.<br /><br />
                        Avant de le lancer, vérifier :<ul><li>Que le targeting est activé sur les pistes ayant du contenu.</li>
                            <li>Que la timeline est active.</li>
                        </ul>
                        <div className="field">
                            <label className="label">Délai entre chaque commande (augmenter en cas de soucis)</label>
                            <div className="control">
                                <input className="input" max="2000" min="1" value={this.state.delay} placeholder="Delay (ms)" required type="number" onChange={this.changeDelay.bind(this)} />
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="modal-card-foot is-justify-content-center">
                    <button className="button is-success" onClick={this.createScript.bind(this)}>Télécharger</button>
                </footer>
            </div>
        </div>)
    }
}

export default AHKExport;