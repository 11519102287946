import React, { Component } from 'react';
import Timecode from 'smpte-timecode';
import TimecodeTag from './TimecodeTag'
import TextExport from './TextExport'
import AHKExport from './AHKExport'


class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            framerate: 25,
            originalText: [],
            translatedText: [],
            textModal: false
        }
    }

    componentDidMount() {
        let text = JSON.parse(window.localStorage.getItem('text'));



        // Extract the selected text from the original text
        for (let textItem of text) {
            if (textItem['original']) {
                const inlineStyleRanges = JSON.parse(textItem['original']).blocks[0].inlineStyleRanges;
                if (inlineStyleRanges.length > 0) { // If we have some bold, let's add it to the state
                    this.extractBold(JSON.parse(textItem['original']).blocks[0], textItem['Start Time'], textItem['End Time'], 'originalText');

                    if (textItem['translated']) {
                        this.extractBold(JSON.parse(textItem['translated']).blocks[0], textItem['Start Time'], textItem['End Time'], 'translatedText');
                    }
                }
            }
        }
    }

    extractBold(block, start, end, textSource) {
        const blockLength = block.text.length;
        const blockStartTC = Timecode(start, this.state.framerate);
        const blockEndTC = Timecode(end, this.state.framerate);
        const blockDuration = Timecode(blockEndTC.frameCount - blockStartTC.frameCount, this.state.framerate);

        for (let range of block.inlineStyleRanges) {
            if (range.style !== 'BOLD') return;

            const rangeText = block.text.substr(range.offset, range.length);

            if (rangeText.replace(' ', '') === '') return;

            let text = this.state[textSource];

            // Initialize TC at the start
            let rangeStartTC = Timecode(blockStartTC.frameCount, this.state.framerate);
            let rangeEndTC = Timecode(blockStartTC.frameCount, this.state.framerate);


            // Set start and end corresponding to percentage of text in range
            rangeStartTC = rangeStartTC.add(Math.round(blockDuration * (range.offset / blockLength)));
            rangeEndTC = rangeEndTC.add(Math.round(blockDuration * ((range.offset + range.length) / blockLength)));


            let duration = Timecode(rangeEndTC.frameCount - rangeStartTC.frameCount, this.state.framerate)

            text.push({
                text: rangeText,
                start: rangeStartTC.toString(),
                end: rangeEndTC.toString(),
                duration: duration
            });

            if (textSource === 'originalText') this.setState({ originalText: text })
            else if (textSource === 'translatedText') this.setState({ translatedText: text })
        }
    }

    // Helper function
    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    exportProject() {
        const filename = window.localStorage.getItem('filename');
        const text = window.localStorage.getItem('text');
        const translate = window.localStorage.getItem('translate');

        this.download(filename + '.json', JSON.stringify({
            text: text,
            key: 'mvtbl',
            translate: translate,
            filename: filename,
        }));
    }


    toggleTextModal() {
        if (this.state.textModal) {
            this.setState({ textModal: false })
        } else {
            this.setState({ textModal: true })
        }
    }
    toggleAHKModal() {
        if (this.state.ahkModal) {
            this.setState({ ahkModal: false })
        } else {
            this.setState({ ahkModal: true })
        }
    }


    render() {

        let totalDuration = 0;
        for (let item of this.state.originalText) {
            totalDuration += item.duration.frameCount;
        }
        totalDuration = Timecode(totalDuration, this.state.framerate);

        let durationColor = 'is-info';
        if (totalDuration.frameCount < 1750) {
            durationColor = 'is-danger';
        }
        else if (totalDuration.frameCount < 2250) {
            durationColor = 'is-warning';
        }
        else if (totalDuration.frameCount > 3250) {
            durationColor = 'is-danger';
        }
        else if (totalDuration.frameCount > 2750) {
            durationColor = 'is-warning';
        }

        return (
            <div className='mx-6'>
                <div className='content has-text-centered '>
                    <span className={durationColor + ' tag is-large is-light'}>Durée : {totalDuration.toString()}</span>
                </div>
                {this.state.originalText.map((text, index) => (
                    <div key={index} className='is-flex mx-6 mb-3'>
                        <TimecodeTag start={text.start} end={text.end} />
                        <span className='has-text-weight-semibold ml-5'>{text.text}</span>
                    </div>
                ))}
                <div className='mt-6 content is-flex is-justify-content-space-evenly'>
                    <button className='button is-info' onClick={this.toggleTextModal.bind(this)}>Télécharger le texte</button>
                    <button className='button is-info' onClick={this.toggleAHKModal.bind(this)} >Télécharger le script AHK</button>
                    <button className='button is-info' onClick={this.exportProject.bind(this)} >Exporter le projet</button>
                </div>

                {/* TEXT EXPORT */}
                <TextExport
                    enable={this.state.textModal}
                    originalText={this.state.originalText}
                    translatedText={this.state.translatedText}
                    close={this.toggleTextModal.bind(this)}
                />
                <AHKExport
                    enable={this.state.ahkModal}
                    originalText={this.state.originalText}
                    close={this.toggleAHKModal.bind(this)}
                />

                {/* <div className='content has-text-centered mt-6 '> */}
                {/* <article className="message mx-6 is-info">
                        <div className="message-header">
                            <p>Script AutoHotKey</p>
                        </div>
                        <div className="message-body">
                            Script à utiliser avec AutoHotKey.<br />
                            Avant de le lancer, vérifier :
                            <ul>
                                <li>Que le targeting est activé sur les pistes ayant du contenu.</li>
                                <li>Que la timeline est active.</li>
                            </ul>

                            <button className='button is-info' onClick={this.createScript.bind(this)}>Télécharger le script AHK</button>
                        </div>
                    </article> */}

                {/* </div> */}
            </div >)
    }
}

export default Review;