import React, { Component } from 'react';
import draftToMarkdown from 'draftjs-to-markdown';


class TextExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            includeTimecode: true,
            includeTranslation: false,
            selectedOnly: true,
            markdown: false,
        }
    }
    generateTextExport() {

        // If all the text 
        if (!this.state.selectedOnly) {
            let text = JSON.parse(window.localStorage.getItem('text'));
            let textExportOriginal = '';
            let textExportTranslated = '';

            for (let textItem of text) {
                if (textItem['original']) {
                    if (this.state.includeTimecode) textExportOriginal += '\n' + textItem['Start Time'] + ' - ' + textItem['End Time'] + '\n';
                    if (this.state.markdown) {
                        textExportOriginal += draftToMarkdown(JSON.parse(textItem['original'])) + '\n';
                    } else {
                        textExportOriginal += JSON.parse(textItem['original']).blocks[0].text + '\n';
                    }
                }
                if (textItem['translated']) {
                    if (this.state.includeTimecode) textExportTranslated += '\n' + textItem['Start Time'] + ' - ' + textItem['End Time'] + '\n';
                    if (this.state.markdown) {
                        textExportTranslated += draftToMarkdown(JSON.parse(textItem['translated'])) + '\n';
                    } else {
                        textExportTranslated += JSON.parse(textItem['translated']).blocks[0].text + '\n';
                    }
                }
            }

            let textExport = textExportOriginal;
            if (this.state.includeTranslation) {
                textExport += '\n\n ' + textExportTranslated;
            }
            // Remove starting line break
            if (textExport.substring(0, 1) === '\n') textExport = textExport.substring(2, textExport.length);
            // Remove double line break if markdown
            if (this.state.markdown) {
                textExport = textExport.replace(/\n\n/g, '\n');
            }
            return textExport;
        }



        // If only selected text
        const originalText = this.props.originalText;
        let translatedText;
        if (this.props.translatedText) translatedText = this.props.translatedText;

        let textExport = '';

        for (let i = 0; i < originalText.length; i++) {
            if (originalText[i].text) {
                if (this.state.includeTimecode) textExport += '\n' + originalText[i].start + ' - ' + originalText[i].end + '\n';
                textExport += originalText[i].text + '\n';
            }
        }

        if (this.state.includeTranslation) {
            textExport += '\n';
            for (let i = 0; i < translatedText.length; i++) {
                if (translatedText[i].text) {
                    if (this.state.includeTimecode) textExport += '\n' + translatedText[i].start + ' - ' + translatedText[i].end + '\n';
                    textExport += translatedText[i].text + '\n';
                }
            }
        }
        // Remove starting line break
        if (textExport.substring(0, 1) === '\n') textExport = textExport.substring(2, textExport.length);
        return textExport;
    }

    download() {
        const text = this.generateTextExport();
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', window.localStorage.getItem('filename') + '.txt');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    toggleTimecode() {
        if (this.state.includeTimecode) {
            this.setState({ includeTimecode: false })
        } else {
            this.setState({ includeTimecode: true })
        }
    }

    toggleTranslation() {
        if (this.state.includeTranslation) {
            this.setState({ includeTranslation: false })
        } else {
            this.setState({ includeTranslation: true })
        }
    }

    toggleSelectedOnly() {
        if (this.state.selectedOnly) {
            this.setState({ selectedOnly: false })
        } else {
            this.setState({ selectedOnly: true })
        }
    }
    toggleMarkdown() {
        if (this.state.markdown) {
            this.setState({ markdown: false })
        } else {
            this.setState({ markdown: true })
        }
    }

    render() {
        return (<div className={this.props.enable ? 'modal is-active' : 'modal'}>
            <div className="modal-background" onClick={this.props.close}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Télécharger le texte</p>
                    <button className="delete" aria-label="close" onClick={this.props.close}></button>
                </header>
                <section className="modal-card-body">


                    <form className='content mx-2'>
                        {/* Texte complet selectionné */}
                        <div className="field">
                            <div className="control">
                                <label className="checkbox" >
                                    <input className='mr-2' checked={this.state.selectedOnly ? true : null} type="checkbox" id="includeTimecode" onChange={this.toggleSelectedOnly.bind(this)} />
                                    Texte sélectionné uniquement
                                </label>
                            </div>
                        </div>

                        {/* Markdown */}
                        {!this.state.selectedOnly ?
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox" >
                                        <input className='mr-2' type="checkbox" id="includeTimecode" onChange={this.toggleMarkdown.bind(this)} />
                                        Markdown
                                    </label>
                                </div>
                            </div> : null}



                        {/* Traduction */}
                        {this.props.translatedText ?
                            <div className="field">
                                <div className="control">
                                    <label className="checkbox" >
                                        <input className='mr-2' type="checkbox" id="includeTimecode" onChange={this.toggleTranslation.bind(this)} />
                                        Inclure la traduction
                                    </label>
                                </div>
                            </div> : null}
                        {/* Timecode */}
                        <div className="field">
                            <div className="control">
                                <label className="checkbox" >
                                    <input className='mr-2' type="checkbox" id="includeTimecode" checked={this.state.includeTimecode ? true : null} onChange={this.toggleTimecode.bind(this)} />
                                    Inclure les timecode
                                </label>
                            </div>
                        </div>
                    </form>


                    {/* PREVIEW */}
                    <article className="message is-small">
                        <div className="message-header">
                            <p>Prévisualisation</p>
                        </div>
                        <div className="message-body" style={{ whiteSpace: 'pre-wrap' }}>
                            {this.generateTextExport()}
                        </div>
                    </article>


                </section>
                <footer className="modal-card-foot is-justify-content-center">
                    <button className="button is-success" onClick={this.download.bind(this)}>Exporter</button>
                </footer>
            </div>
        </div>)
    }
}

export default TextExport;