import React, { Component } from 'react';
class TimecodeTag extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = { selection: '', translatedText: '', originalText: '' }
    // }
    // selectText(selection) {
    //     this.setState({ selection: selection }, () => { console.log(this.state.selection) })
    // }


    render() {
        return (
            <>
                <span className='tag is-small'>{this.props.start} - {this.props.end}</span>
                {this.props.buttonSelect ?
                    <span onClick={this.props.buttonSelect} className='tag is-small ml-3 is-success is-clickable'>Sélectionner</span>
                    : null
                }<br />
            </>
        )
    }
}

export default TimecodeTag;